import TomSelect from "tom-select";

document.addEventListener("turbo:load", () => {
  if (document.querySelector(".tom-select")) {
    document.querySelectorAll(".tom-select").forEach((el) => {
      new TomSelect(el, {
        plugins: {
          remove_button: {
            title: "Remove this item"
          }
        },
        persist: false,
        create: false
      });
    });
    document.querySelectorAll(".tom-select").forEach((el) => {
      el.classList.remove("form-control");
    });
  }
});
