import "@hotwired/turbo-rails";
import "@/tom-select.js";
import * as bootstrap from "bootstrap";

window.bootstrap = bootstrap;

//Suboptimal, but works as a workaround until we get stimulus fully implemented
document.addEventListener("turbo:submit-end", (event) => {
  if (!event.detail.success) {
    setTimeout(() => {
      document.dispatchEvent(new Event("turbo:load"));
    }, 200);
  }
});
